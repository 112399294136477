import React, { forwardRef } from 'react';
import classNames from 'classnames';
export const InternalPanel = forwardRef((props, ref) => {
    const { prefixCls, className, children, size, style = {} } = props;
    const panelClassName = classNames(`${prefixCls}-panel`, {
        [`${prefixCls}-panel-hidden`]: !size,
    }, className);
    return (React.createElement("div", { ref: ref, className: panelClassName, style: Object.assign(Object.assign({}, style), { flexBasis: size }) }, children));
});
if (process.env.NODE_ENV !== 'production') {
    InternalPanel.displayName = 'Panel';
}
const Panel = () => null;
export default Panel;
